#assuta-frame-workspace-container {
    margin: 38px;
    height: calc(100% - 78px);
    width: calc(100% - 78px);
    display: flex;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px 10px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 1px 10px 10px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 10px 10px rgba(0,0,0,0.3);
}
@media screen and (max-width: 1380px) and (orientation: landscape) {
    #assuta-frame-workspace-container {
        display: block;
    }
    #assuta-frame-workspace {
        width: 100% !important;
        height: calc(100% - 130px) !important;
    }
    .assuta-frame-workspace-header {
        align-items: center!important;
        height: unset!important;
        padding: 10px;
        border-bottom: 1px solid rgba(15, 45, 108, 0.7)!important;
    }
    .Information-Content {
        justify-content: space-evenly!important;
        /*border-right: 2px solid green;*/
        /*border-left: 2px solid green;*/
    }
}
@media screen and (max-width: 1100px) and (orientation: landscape) {

    .Information-Content-Item {
        width: 100% !important;
        min-width: 100% !important;
    }
    .Information-Content-Item-Paper {
        width: 100% !important;
        min-width: 100% !important;
        align-items: center!important;
    }
    .InformationInput-TransferList {
        /*width: 40% !important;*/
        min-width: 400px!important;
        width: 400px!important;
    }
    .InformationInput-TransferList-Search {
        /*width: 40% !important;*/
        min-width: 378px!important;
        width: 378px!important;
    }
}
@media screen and (orientation: portrait) {
    #assuta-frame-workspace-container {
        display: block;
    }
    #assuta-frame-workspace {
        width: 100% !important;
        height: calc(100% - 130px) !important;
    }
    .assuta-frame-workspace-header {
        align-items: center!important;
        height: unset!important;
        padding: 10px;
        border-bottom: 1px solid rgba(15, 45, 108, 0.7)!important;
    }
    .Information-Content {
        justify-content: space-evenly!important;
        border-right: 2px solid green;
        border-left: 2px solid green;
    }
}

.Information-Content.managment-frame {
    justify-content: flex-start!important;
    height: 100% !important;
}
.Information-Content {
    width: 100% !important;
    height: calc(100% - 70px) !important;
    justify-content: space-evenly!important;
    overflow-y: auto;
}

.Information-Content-Item-Paper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#assuta-frame-workspace-navbar-logo {
    width: 100%;
}

#assuta-frame-workspace {
    background-color: rgba(255, 255, 255, 0.95);
    height: 100%;
    width: calc(100% - 240px);
    padding: 0px 10px;
}

.assuta-frame-workspace-header {
    height: 82px;
    width: 100%;
    border-bottom: 2px solid rgba(15, 45, 108, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#assuta-frame-workspace-header-label {
    width: max-content;
    min-width: 310px;
    font-size: 28px;
}
#assuta-frame-workspace-header-colabel {
    font-variant-numeric: lining-nums proportional-nums;
    /* h5 */
    font-family: Manrope, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px;
    min-width: 310px;
}
#assuta-frame-workspace-header-time-label{
    font-variant-numeric: lining-nums proportional-nums;
    /* h5 */
    font-family: Manrope, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.5px;
}
#assuta-frame-workspace-body {
    width: 100%;
    max-height: calc(100% - 82px);
    height: 100%;
    padding: 30px 10px;
    overflow: auto;
    /*zoom: 0.9;*/
}
#researcher-body-footer {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: end;
    justify-content: space-between;
}
.assuta-frame-workspace-footer-btn{

}

.InformationInput {
    min-width: 400px !important;
}

.MuiPaper-root.InformationInput {
    background-color: rgba(0,0,0,0.01);
    border: 1px solid rgba(190,190,190,1);
    box-shadow: none;
    min-width: 200px !important;

}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    padding-right: 0;
}

.MuiGrid-item {
    width: max-content;
    min-width: max-content;
}
.TransferList-GridFomControl{
    width: max-content!important;
    min-width: max-content!important;
}
.TransferList-Final{
    width: min-content!important;
}
.MuiAutocomplete-tag {
    max-width: max-content!important;
}

.results-page {
    margin-right: 0!important;
    margin-left: 0!important;
    display: block!important;
}



/* styles.css */
.table-container {
    height: calc(100% - 79px);
    width: 100%;
    overflow-y: hidden;
}

.custom-table {
    border-collapse: collapse;
    width: 100%;
}

.table-header {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.table-body {
    max-height: calc(100% - 40px); /* Adjust this value as needed */
    overflow-y: scroll;
}

.table-row {
    border: 1px solid #ccc;
}

.table-cell {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.delete-button {
    border: none;
    cursor: pointer;
}
.namespace-display{
    width: 100%;
    padding: 10px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

