html, body, #root {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
body, #root {
  position: relative;
  top: 0;
  left: 0;
  overflow: auto;
}



html {
  -moz-transform-origin: 0 0;
  background-image: url("./assets/background.png");
  background-color: #00A4BD;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

