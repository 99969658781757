.welcome-body-container {
    display: flex;
    flex-direction: column;
}

#welcome-page-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#welcome-page-actions {
    margin-top: 80px;
}

.welcome-page-btn {
    width: 250px;
    height: 100px;
}