@media screen and (max-width: 1380px) and (orientation: landscape) {
    #assuta-frame-workspace-navbar {
        width: 100% !important;
        height: 130px!important;
        padding: 10px 0px!important;
    }
    #assuta-frame-workspace-navbar-logo {
        height: 30px;
        width: unset!important;
    }
    .assuta-frame-workspace-navbar-menu {
        /*height: 120px!important;*/
    }
    .assuta-frame-workspace-navbar-header {
        align-items: center!important;
        height: unset!important;
        padding: 10px;
        border-bottom: 1px solid rgba(15, 45, 108, 0.7)!important;
    }
}

@media screen and (orientation: portrait) {
    #assuta-frame-workspace-navbar {
        width: 100% !important;
        height: 130px!important;
        padding: 10px 0px!important;
    }
    #assuta-frame-workspace-navbar-logo {
        height: 30px;
        width: unset!important;
    }
    .assuta-frame-workspace-navbar-menu {
        /*height: 120px!important;*/
    }
    .assuta-frame-workspace-navbar-header {
        align-items: center!important;
        height: unset!important;
        padding: 10px;
        border-bottom: 1px solid rgba(15, 45, 108, 0.7)!important;
    }
}

#assuta-frame-workspace-navbar {
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 240px;
    padding: 0px 10px;
}

#assuta-frame-workspace-navbar-logo {
    width: 100%;
}
.assuta-frame-workspace-navbar-header {
    height: 82px;
    width: 100%;
    border-bottom: 2px solid rgba(15, 45, 108, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#assuta-frame-workspace-navbar-footer {
    height: 70px;
    width: 100%;
    border-top: 2px solid rgba(15, 45, 108, 1);
    display: flex;
    align-items: center;
    padding: 20px;
}
#assuta-frame-workspace-navbar-footer-container {
    height: 100%;
    width:100%;
    display: flex;
    /*flex-direction: column;*/
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}
#assuta-frame-workspace-navbar-menu {
    height: calc(100% - 152px);
    width: 100%;
    border-top: 2px solid rgba(15, 45, 108, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.assuta-frame-workspace-navbar-footer-icon {
    height: 16px;
    margin-right: 5px;
}
.assuta-frame-workspace-navbar-footer-label {
    margin: 0;
    color: rgba(15, 45, 108, 1);
}
.assuta-frame-workspace-navbar-footer-action{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    cursor: pointer;
}
.assuta-frame-workspace-navbar-footer-action:hover .assuta-frame-workspace-navbar-footer-label {
    color: rgba(15, 45, 108, 0.7)!important;
}