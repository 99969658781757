.assuta-frame-workspace-body-container {
    height: 100%;
    width: 100%
}

/* Hide the scrollbar track (the background) */
::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */
    height: 10px; /* Set the height of the scrollbar */
}

/* Style the scrollbar thumb (the draggable handle) */
::-webkit-scrollbar-thumb {
    background: #888; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners for the thumb */
}

/* Style the scrollbar track when not hovering over it */
::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track */
}

/* Style the scrollbar thumb when hovering over it */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the thumb on hover */
}

/* Style the scrollbar corner (the intersection of horizontal and vertical scrollbars) */
::-webkit-scrollbar-corner {
    background: #f1f1f1; /* Color of the corner */
}