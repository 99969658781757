@media screen and (max-width: 1380px) and (orientation: landscape) {
    #assuta-frame-workspace-environment-management{
        flex-direction: row !important;
    }
    .assuta-frame-workspace-environment-management-col {
        height: 100% !important;
        width: calc(50% - 1px) !important;
    }
    #assuta-frame-workspace-namespace-management-col {
        border-right: 2px solid rgba(15, 45, 108, 0.7);
        border-bottom: unset !important;
    }
}
@media screen and (max-width: 1100px) and (orientation: landscape) {
    #assuta-frame-workspace-environment-management{
        flex-direction: row !important;
    }
    .assuta-frame-workspace-environment-management-col {
        height: 100% !important;
        width: calc(50% - 1px) !important;
    }
    #assuta-frame-workspace-namespace-management-col {
        border-right: 2px solid rgba(15, 45, 108, 0.7);
        border-bottom: unset !important;
    }
}
@media screen and (orientation: portrait) {

}

#assuta-frame-workspace-environment-management{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#assuta-frame-workspace-user-management-col{
    padding-top: 30px;
}

.assuta-frame-workspace-environment-management-col {
    width: 100%;
    height: calc(50% - 1px);
}

#assuta-frame-workspace-namespace-management-col {
    border-bottom: 2px solid rgba(15, 45, 108, 0.7);
}