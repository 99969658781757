@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Raleway&display=swap');
* {
  font-family: Raleway, sans-serif!important;
  color: rgba(15, 45, 108, 1)!important;
}

.MuiInputLabel-root{
  font-family: Raleway, sans-serif!important;
  color: rgba(15, 45, 108, 1)!important;
}
.MuiAutocomplete-inputRoot{
  flex-wrap: nowrap!important;
}
.App {
  text-align: center;
}

.assuta-top-container {
  height: 100%;
  width: 100%;
  overflow-y: auto;

}

div {
  box-sizing: border-box;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(15, 45, 108, 1);
  color: #FEFEFE!important;
  font-family: Manrope, sans-serif;
  font-size: 16px;
  min-width: 192px;
  min-height: 40px;
  border: none;
}

.btn:hover {
  border: 1px solid #00A4BD;
  cursor: pointer;
  box-shadow: unset;
  color: #d4fff5;
  background: rgba(15, 45, 108, 0.8);
  visibility: visible;
}

.btn:disabled {
  cursor: not-allowed!important;
  background: rgba(15, 45, 108, 0.6) !important;
  box-shadow: unset!important;
  border: none!important;
}

.btn:active {
  border: 1px solid #00A4BD;
  cursor: pointer;
  color: #d4fff5;
  background: rgba(15, 45, 108, 0.8);
  box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.61) inset;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.61) inset;
  -moz-box-shadow: 0px 0px 6px 3px rgba(0,0,0,0.61) inset;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}
