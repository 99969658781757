@-webkit-keyframes autofill {
    0%,100% {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

.auth-action-container {
    min-width: 430px;
    min-height: 550px;
    background-color: rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    box-shadow: 1px 1px 10px 10px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 1px 10px 10px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 10px 10px rgba(0,0,0,0.3);
    border-radius: 25px;
    padding: 10px 25px 10px 25px;
}
.auth-action-container #auth-action-container-header {
    max-width: 90%;
}

.auth-action-name-container {
    margin-top: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 44.8px; /* 128% */
    border-bottom: 1px solid rgba(117, 121, 122, 0.6);
}
.auth-action-name {
    font-size: 35px;
    display: block;
    overflow: hidden;
}
.auth-action-details {
    font-size: 14px;
    color: rgba(117, 121, 122, 1);
}

.auth-action-form-container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.auth-action-form-input {
    margin-top: 25px!important;
    width: 100% !important;
}

.auth-action-form-submit{
    margin-top: 45px;
    width: 80%;
}

@media screen and (orientation: portrait) {
    .auth-action-form-container > .MuiGrid-item {
        width: 100%!important;

    }
    .auth-action-form-input > div > input{
        width: 100%!important;
    }
}